interface DeepResourceCodes {
    children: [];
    resourceCodes: string;
}

export interface floatJson {
    [key: string]: string;
}

export class DeepLoop {
    // private data: [] = [];
    // private str: string = '';

    public getResources(data: any[]): string {
        let str2: string = '';
        data.forEach((value: DeepResourceCodes) => {
            if (value.resourceCodes) {
                str2 += value.resourceCodes + ',';
            }
            if (value.children && value.children.length > 0) {
                str2 += this.getResources(value.children);
            }

        });
        return str2;
    }

    public deepCopy(source: any, isClearNullList: boolean = true): any {
        const targetObj: any = source.constructor === Array ? [] : {}; // 判断复制的目标是数组还是对象
        for (const keys in source) { // 遍历目标
            if (source.hasOwnProperty(keys)) {
                if (source[keys] && typeof source[keys] === 'object') { // 如果值是对象，就递归一下
                    targetObj[keys] = source[keys].constructor === Array ? [] : {};
                    if (source[keys].constructor === Array && source[keys].length === 0 && isClearNullList) {
                        targetObj[keys] = '';
                    } else {

                        targetObj[keys] = this.deepCopy(source[keys]);
                    }
                } else { // 如果不是，就直接赋值
                    targetObj[keys] = source[keys];
                }
            }
        }
        return targetObj;
    }

    public deepFlatJson(data: any, key: string, parentKey: string): floatJson {

        // 递归平铺树状数据
        let result: any = {};
        data.forEach((value: any) => {
            result[value[key]] = value[parentKey];


            if (value.children && value.children.length !== 0) {
                // this.deepFlatJson(value.children, key, parentKey);
                // result = Object.assign(result, this.deepFlatJson(value.children, key, parentKey));
                result = {
                    ...result,
                    ...this.deepFlatJson(value.children, key, parentKey),
                };
            }
        });

        return result;
    }

    public deepFlatJsonWithResource(data: any): string[] {
        let result: string[] = [];
        data.forEach((value: any) => {
            for (const i in value.resources) {
                result.push(value.resources[i].id);
            }
            result.push(value.id);

            if (value.children && value.children.length !== 0) {

                result = [
                    ...result,
                    ...this.deepFlatJsonWithResource(value.children),
                ];
            }

        });

        return result;
    }


    public static getDataRoleForDeepLoop(roleList: any, dataRole: string, arr: string[]): any {

        for (const i in roleList) {

            // if (!roleList[i].disabled) {
            if (roleList[i].code === dataRole) {
                arr.push(roleList[i].code);
                return arr;
            }

            if (roleList[i].children && roleList[i].children.length) {
                arr.push(roleList[i].code);
                let result = this.getDataRoleForDeepLoop(roleList[i].children, dataRole, arr);
                if (result) {
                    return result;
                }
            }

            // }

        }
        arr.pop();
    }
}
