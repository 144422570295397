import { BasicsClass } from '@/common/BasicsClass';

export const getForGroupCode = (cb: Function) => { //获取组织机构  非PID  当前登录者的存在养殖场的组织机构树
    new BasicsClass().BasicGet('/animal/web/provinceCityDistrict/getForGroupCode', {}, false, false, cb)
}
export const getForGroupCodelistCity = (cb: Function) => { //获取市组织机构
    new BasicsClass().BasicGet('/animal/web/provinceCityDistrict/listCity', {}, false, false, cb)
}

export const getGroupCodeForUser = (cb: Function) => { //获取组织机构  PID  当前登录者可选择的组织机构
    new BasicsClass().BasicGet('/animal/web/provinceCityDistrict/getGroupCodeForUser', { pid: "-1", flag: "0" }, false, false, cb)
}

export const getForGroupCodeForChange = (cb: Function) => { //获取组织机构  非PID  数据大屏组织机构
    new BasicsClass().BasicGet('/animal/web/provinceCityDistrict/getForGroupCodeForChange', {}, false, false, cb)
}

export const comboGroupListForInsurance = (cb: Function) => { //获取组织机构  非PID  保险的服务区域
    new BasicsClass().BasicGet('/animal/web/provinceCityDistrict/comboGroupListForInsurance', {}, false, false, cb)
}

export const groupCode = (cb: Function) => { //获取组织机构  非PID  数据统计大屏-银行-组织机构
    new BasicsClass().BasicGet('/animal/web/bigScreenDataStatBank/groupCode', {}, false, false, cb)
}

export const bankGroupCode = (cb: Function) => {  // 获取组织机构  非PID  3.1.3版本之前的银行数据大屏  此时还没有新大屏
    new BasicsClass().BasicGet('/animal/web/bigScreenForBankNew/bankGroupCode', {}, false, false, cb)
}
export const governmentGroupCodeData = (cb: Function) => {
    new BasicsClass().BasicGet('/animal/web/bigScreenGovernmentNew/governmentGroupCode', {}, false, false, cb)
}




export const getCompanyList = (groupCode?: string, cb?: Function) => {  //养殖企业
    new BasicsClass().BasicGet('/animal/web/farm/getCompanyList', { groupCode }, false, false, cb)
}

export const getFarmList = (companyId?: string, groupCode?: string, cb?: Function) => {  //养殖场
    new BasicsClass().BasicGet('/animal/web/farm/getFarmList', { companyId, groupCode }, false, false, cb)
}

export const getHouseList = (farmId?: string, cb?: Function) => {  //养殖舍
    new BasicsClass().BasicGet("/animal/web/animalMark/houseList", { farmId }, false, false, cb)
}

export const getColumnList = (houseId?: string, cb?: Function) => { //养殖栏
    new BasicsClass().BasicGet("/animal/web/animalMark/columnList", { houseId }, false, false, cb)
}

export const comboList = (groupCode?: string, cb?: Function) => {
    new BasicsClass().BasicGet("/animal/web/farm/comboList", { groupCode }, false, false, cb)
}


//App菜单管理 /common/getInfoByConfTypeId
export const getInfoByConfTypeId314 = (cb: Function) => {
    new BasicsClass().BasicGet("/animal/web/common/getInfoByConfTypeId", { businessCode: 33 }, false, false, cb)
}




//AI人工审核  发情提醒 、 分娩预测 公用的一个接口 --庄贵宾
export const getAiEsOrDe = (form: any, page: any, event: string, cb: Function) => {
    new BasicsClass().BasicGet("/animal/web/aIAlert/list", { ...form, ...page, event }, false, false, cb)
}


//3.1.5  银行 
//新增银行 /loanBasicInformationConfig/getBankTree
export const getBankTree315 = (cb: Function) => {  //新增银行  编辑银行 左侧树结构
    new BasicsClass().BasicGet("/animal/web/loanBasicInformationConfig/getBankTree", {}, false, false, cb)
}
export const addBank315 = (form: any, cb: Function, ecb: Function) => {  //新增银行  添加银行
    new BasicsClass().BasicPost("/animal/web/loanBasicInformationConfig/addBank", { ...form }, true, true, true, cb, ecb)
}
export const updateBank315 = (form: any, cb: Function, ecb: Function) => {
    new BasicsClass().BasicPost1("/animal/web/loanBasicInformationConfig/updateBank", { ...form }, true, true, true, cb, ecb)
}



//3.1.5平台用户管理  3.1.5新增  替换之前接口 更改数据结构
export const getBankList315 = (id: string, cb: Function) => {
    new BasicsClass().BasicGet1("/animal/web/user/bankList", { id }, false, false, cb)
}

//3.1.5贷款管理  //所属区域
export const getLoanGroupCode315 = (cb: Function) => {
    new BasicsClass().BasicGet("/animal/web/loanAudit/getLoanGroupCode", {}, false, false, cb)
}
//3.1.5贷款管理  //所属企业 
export const getLoanCompany315 = (groupCode: string, cb: Function) => {
    new BasicsClass().BasicGet("/animal/web/loanAudit/getLoanCompany", { groupCode }, false, false, cb)
}
//3.1.5贷款管理  //养殖场
export const getLoanFarm315 = (groupCode: string, companyId: string, cb: Function) => {
    new BasicsClass().BasicGet("/animal/web/loanAudit/getLoanFarm", { groupCode, companyId }, false, false, cb)
}
//3.1.5贷款管理  //查询列表
export const getloanList315 = (form: any, tableObj: Object, cb: Function) => {
    new BasicsClass().BasicGet("/animal/web/loanAudit/loanList", { ...form, ...tableObj }, false, false, cb)
}
//3.1.55贷款管理 删除贷款记录
export const deleteLoanApplyRecord315 = (id: string, cb: Function) => {
    new BasicsClass().BasicGet("/animal/web/loanAudit/deleteLoanApplyRecord", { id }, false, false, cb)
}

//3.1.5新增贷款  //选择支行
export const getCurrentBankfiliale315 = (cb: Function) => {
    new BasicsClass().BasicGet("/animal/web/loanAudit/getCurrentBankfiliale", {}, false, false, cb)
}
//3.1.5新增贷款  //获取企业列表
export const getCompanyForLoan315 = (companyType: string, bankId: string, cb: Function) => {
    1
    new BasicsClass().BasicGet("/animal/web/loanAudit/getCompanyForLoan", { companyType, bankId }, false, false, cb)
}
//3.1.5新增贷款  //获取企业列表
export const getFarmForLoan315 = (companyId: string, bankId: string, cb: Function) => {
    new BasicsClass().BasicGet("/animal/web/loanAudit/getFarmForLoan", { companyId, bankId }, false, false, cb)
}
//3.1.5新增贷款  //获取列表
export const getmortgageSelective315 = (farmId: string, label: string, cb: Function) => {
    new BasicsClass().BasicGet("/animal/web/loanAudit/mortgageSelective", { farmId, label }, false, false, cb)
}
//3.1.5新增贷款  //保存 新增 
export const addLoanApplyRecord315 = (form: any, cb: Function) => {
    new BasicsClass().BasicPost("/animal/web/loanAudit/addLoanApplyRecord", { ...form }, true, true, true, cb)
}

//3.1.55贷款详情 - 查看贷款记录
export const getloanDetail315 = (id: string, cb: Function) => {
    new BasicsClass().BasicGet("/animal/web/loanAudit/loanDetail", { id }, false, false, cb)
}
//3.1.5贷款详情-活体抵押耳标号
export const mortgageDetail315 = (id: string, pageNo: string, cb: Function) => {
    new BasicsClass().BasicGet("/animal/web/loanAudit/mortgageDetail", { id, pageNo }, false, false, cb)
}

// 3.1.5 总行/分行列表
export const bigbankList315 = (cb: Function) => {
    new BasicsClass().BasicGet("/animal/web/bigScreenForBank2/bankList", {}, false, false, cb)
}
//3.1.5 地图背景 地区
export const bigbankMap315 = (bankId: string, cb: Function) => {
    new BasicsClass().BasicGet("/animal/web/bigScreenForBank2/bankMap", { bankId }, false, false, cb)
}
//3.1.5 地图打点  
export const bankmapStatistics315 = (bankId: string, cb: Function, ecb: Function) => {
    new BasicsClass().BasicGet("/animal/web/bigScreenForBank2/mapStatistics", { bankId }, false, false, cb, ecb)
}
//3.1.5 贷款发放排行 
export const loansQuantity315 = (bankId: string, cb: Function) => {
    new BasicsClass().BasicGet("/animal/web/bigScreenForBank2/loansQuantity", { bankId }, false, false, cb)
}
// 3.1.5 抵押活体统计 
export const statisticsMortgage315 = (bankId: string, cb: Function) => {
    new BasicsClass().BasicGet("/animal/web/bigScreenForBank2/statisticsMortgage", { bankId }, false, false, cb)
}
//3.1.5 贷款发放总额  
export const banktotalLoan315 = (bankId: string, cb: Function) => {
    new BasicsClass().BasicGet("/animal/web/bigScreenForBank2/totalLoan", { bankId }, false, false, cb)
}
//3.1.5 活体监管数量 
export const statisticsTotal315 = (bankId: string, cb: Function) => {
    new BasicsClass().BasicGet("/animal/web/bigScreenForBank2/statisticsTotal", { bankId }, false, false, cb)
}
//3.1.5 贷款月度统计 
export const statisticsMonth315 = (bankId: string, cb: Function) => {
    new BasicsClass().BasicGet("/animal/web/bigScreenForBank2/statisticsMonth", { bankId }, false, false, cb)
}
// 3.1.5 养殖监管统计 
export const cultureStatistics315 = (bankId: string, cb: Function) => {
    new BasicsClass().BasicGet("/animal/web/bigScreenForBank2/cultureStatistics", { bankId }, false, false, cb)
}


//老银行大屏  3.1.5接口汇总 更改
//今日是否有出栏
export const todayOutColumn315 = (form: any, cb: Function) => {
    new BasicsClass().BasicGet('/animal/web/bigScreenForBankNew/todayOutColumn', {
        animalTypeId: form.currentType,
        groupCode: form.id,
        farmId: form.farmId,
        bankId: form.bankId
    }, false, true, cb);
}
// 获取未盘点原因数量3
export const noInventoryCause315 = (form: any, cb: Function) => {
    new BasicsClass().BasicGet('/animal/web/bigScreenForBankNew/noInventoryCause', {
        animalTypeId: form.currentType,
        groupCode: form.id,
        farmId: form.farmId,
        bankId: form.bankId
    }, false, true, cb);
}
//监管  实时盘点
export const getTakeStockData315 = (form: any, cb: Function) => {
    new BasicsClass().BasicGet('/animal/web/bigScreenForBankNew/getTakeStockData', {
        animalTypeId: form.currentType,
        groupCode: form.id,
        farmId: form.farmId,
        bankId: form.bankId
    }, false, true, cb);
}
//弹窗5
export const noInventoryItem315 = (form: any, cb: Function) => {
    new BasicsClass().BasicGet('/animal/web/bigScreenForBankNew/noInventoryItem', {
        animalTypeId: form.currentType,
        groupCode: form.id,
        farmId: form.farmId,
        bankId: form.bankId
    }, false, true, cb);
}
//弹窗6
export const noInventoryCauseItem315 = (form: any, cb: Function) => {
    new BasicsClass().BasicGet('/animal/web/bigScreenForBankNew/noInventoryCauseItem', {
        animalTypeId: form.currentType,
        groupCode: form.id,
        farmId: form.farmId,
        bankId: form.bankId
    }, false, true, cb);
}
//老银行大屏  新增银行列表接口
export const getOldBankCurrentBankfiliale315 = (cb: Function) => {
    new BasicsClass().BasicGet('/animal/web/bigScreenForBankNew/getCurrentBankfiliale', {}, false, true, cb);
}
//老银行大屏  获取左侧选择地址的接口  
export const bankGroupCodeNav315 = (bankId: string, cb: Function) => {
    new BasicsClass().BasicGet('/animal/web/bigScreenForBankNew/bankGroupCode', { bankId }, false, true, cb)
}

//3.1.5  银行出栏解押  接口变更  变为查询全部
export const getForGroupCode315 = (cb: Function) => {  //出栏申请获取所属机构
    new BasicsClass().BasicGet('/animal/web/releaseFromCustody/getForGroupCode', { flag: 1 }, false, true, cb)
}
export const getForFarms315 = (cb: Function) => {  //出栏申请获取所属养殖场
    new BasicsClass().BasicGet('/animal/web/releaseFromCustody/getForFarms', { flag: 1 }, false, true, cb)
}
export const getForBanks315 = (cb: Function) => {  //出栏申请获取所属银行
    new BasicsClass().BasicGet('/animal/web/releaseFromCustody/getForBanks', { flag: 1 }, false, true, cb)
}
export const getForTargetCodes315 = (cb: Function) => {  //出栏申请获取目标地址
    new BasicsClass().BasicGet('/animal/web/releaseFromCustody/getForTargetCodes', { flag: 1 }, false, true, cb)
}
export const getForVarieties315 = (cb: Function) => {  //出栏申请获取品种
    new BasicsClass().BasicGet('/animal/web/releaseFromCustody/getForVarieties', { flag: 1 }, false, true, cb)
}

//3.1.6硬件管理  生物行骗耳标管理  --  物联网智能设备管理
export const getJDAnimalFarmList316 = (cb: Function) => {  //出栏申请获取品种
    new BasicsClass().BasicGet('/animal/web/JDAnimal/farmList', {}, false, true, cb)
}

export const getGroupCodeForUser319 = (pid: any, isInternational: string, cb: Function) => { //获取组织机构  PID  当前登录者可选择的组织机构   ---> 3.1.9海外版变更
    if (pid === undefined) pid = -1
    new BasicsClass().BasicGet('/animal/web/provinceCityDistrict/getGroupCodeForUser', { pid, isInternational, flag: "0" }, false, false, cb)
}
export const getCompanysList = (form: any, tablePage: any, cb: Function) => {
    let formData: any = JSON.parse(JSON.stringify(form))
    Array.isArray(form.newGroupCode) ? formData.groupCode = formData.groupCode.at(-1) : formData.groupCode = formData.newGroupCode;
    formData.groupCode == '' ? formData.groupCode = 0 : ''
    console.log(formData)
    new BasicsClass().BasicGet('/animal/web/company/list', { ...formData, ...tablePage }, false, false, cb)
}
export const getprovinceCityDistrictForGroupCode = (cb: Function) => { //获取组织机构  非PID  当前登录者的存在养殖场的组织机构树  大屏有这个接口 但是319不更改大屏特此新写一个
    new BasicsClass().BasicGet('/animal/web/provinceCityDistrict/getForGroupCode', { flag: 1 }, false, false, cb)
}
export const getFarm_List319 = (form: any, tablePage: any, cb: Function) => {
    let formData1: any = JSON.parse(JSON.stringify(form))
    Array.isArray(form.groupCode) ? formData1.groupCode = formData1.groupCode.at(-1) : formData1.groupCode = formData1.groupCode;
    console.dir(form)
    new BasicsClass().BasicGet('/animal/web/farm/list', { ...formData1, ...tablePage }, false, false, cb)
}

//ai
export const getHealthWarningList = (form: any, tablePage: any, cb: Function) => {
    new BasicsClass().BasicGet('/animal/web/healthWarning/list', { ...form, ...tablePage }, false, false, cb)
}


export const getSystemAllFarmList = (cb: Function) => {
    new BasicsClass().BasicGet('/animal/web/farm/names', {}, false, false, cb)
}

export const getSystemAllCompanyList = (cb: Function) => {
    new BasicsClass().BasicGet('/animal/web/farm/getFarmCompany', {}, false, false, cb)
}

export const getBigScreenGovernmentVideoGroupCode = (cb: Function) => { //政府大屏  视频 组织机构
    new BasicsClass().BasicGet('/animal/web/bigScreenGovernmentNew/video/governmentGroupCode', { animalTypeId: 2 }, false, false, cb)
}

export const getBigScreenGovernmentVideoList = (form: any, page: any, cb: Function) => {
    new BasicsClass().BasicGet('/animal/web/bigScreenGovernmentNew2/videoList', { ...form, ...page, animalTypeId: 2, streamType: 1 }, false, false, cb, () => { }, false)
}

export const getBigScreenCompanyVideoGroupCode = (animalTypeId: number | string, cb: Function) => { //企业养殖大屏  视频 组织机构
    new BasicsClass().BasicGet('/animal/web/bigScreenForCompanyNew/video/companyGroupCode', { animalTypeId }, false, false, cb)
}
export const getBigScreenCompanyVideoGroupCodeV333 = (animalTypeId: number | string, cb: Function) => { //企业养殖大屏V333  视频 组织机构
    new BasicsClass().BasicGet('/animal/web/bigScreenForCompanyNew/video/companyGroupCodeV333', { animalTypeId }, false, false, cb)
}
export const getBigScreennewInsuranceVideoList = (form: any, page: any, cb: Function) => {//企业养殖大屏  视频
    new BasicsClass().BasicGet('/animal/web/newInsuranceLargeScreen/videoList', { ...form, ...page, streamType: 1 }, false, false, cb, () => { }, false)
}
export const getBigScreennewInsuranceVideoListV333 = (form: any, page: any, cb: Function) => {//企业养殖大屏  视频V333
    new BasicsClass().BasicGet('/animal/web/newInsuranceLargeScreen/videoListV333', { ...form, ...page, streamType: 1 }, false, false, cb, () => { }, false)
}
export const getBigScreenBankNewVideoGroupCode = (animalTypeId: number | string, bankId: number | string, cb: Function) => { //银行大屏  视频 组织机构
    new BasicsClass().BasicGet('/animal/web/bigScreenForBankNew/video/bankGroupCode', { animalTypeId, bankId }, false, false, cb)
}
export const getBigScreenBankNewVideoList = (form: any, page: any, cb: Function) => {
    new BasicsClass().BasicGet('/animal/web/bigScreenForBankNew/videoList', { ...form, ...page, streamType: 1 }, false, false, cb, () => { }, false)
}

export const getBigScreenInsuranceNewVideoGroupCode = (animalTypeId: number | string, cb: Function) => { //保险大屏  视频 组织机构
    new BasicsClass().BasicGet('/animal/web/bigScreenForInsuranceNew/video/insuranceGroupCode', { animalTypeId }, false, false, cb)
}
export const getBigScreenInsuranceNewVideoList = (form: any, page: any, cb: Function) => {
    new BasicsClass().BasicGet('/animal/web/newEnterpriseLargeScreen/videoList', { ...form, ...page, streamType: 1 }, false, false, cb, () => { }, false)
}
export const getBigScreenInsuranceNewVideoGroupCodeV333 = (animalTypeId: number | string, cb: Function) => { //保险大屏V333  视频 组织机构
    new BasicsClass().BasicGet('/animal/web/bigScreenForInsuranceNew/video/insuranceGroupCodeV333', { animalTypeId }, false, false, cb)
}
export const getBigScreenInsuranceNewVideoListV333 = (form: any, page: any, cb: Function) => {//保险大屏V333  视频
    new BasicsClass().BasicGet('/animal/web/newEnterpriseLargeScreen/videoListV333', { ...form, ...page, streamType: 1 }, false, false, cb, () => { }, false)
}


export const getMarketList = (name: string, page: any, cb: Function) => { //获取市场列表数据
    new BasicsClass().BasicGet('/animal/web/appletMarket/list', { name, ...page }, false, false, cb, () => { }, false)
}
export const SaveMarket = (form: any, cb: Function) => {  //新增市场
    new BasicsClass().BasicPost1("/animal/web/appletMarket/save", { ...form }, true, true, true, cb)
}
export const SaveUpdateMarket = (form: any, cb: Function) => {  //更新市场
    new BasicsClass().BasicPost1("/animal/web/appletMarket/update", { ...form }, true, true, true, cb)
}
export const getMarketBaseInfo = (id: string, cb: Function) => { //获取市场基础信息
    new BasicsClass().BasicGet('/animal/web/appletMarket/getBaseInfo', { id }, false, false, cb, () => { }, false)
}

export const DeleteMarket = (id: number, cb: Function) => { //获取市场基础信息
    new BasicsClass().BasicGet('/animal/web/appletMarket/delete', { id }, false, false, cb, () => { }, false)
}


export const SaveTransactionSubmit = (form: any, cb: Function) => { //交易配置提交
    new BasicsClass().BasicPost1("/animal/web/appletMarket/transactionSubmit", { ...form }, true, true, true, cb)
}
export const getTransactionInfo = (id: string, cb: Function) => { //获取市场基础信息
    new BasicsClass().BasicGet('/animal/web/appletMarket/getTransactionInfo', { id }, false, false, cb, () => { }, false)
}




//+------------------------------------------------------------------
//+ 撮合交易小程序后台接口
//+------------------------------------------------------------------
export const getBuyerUserList = (form: any, page: any, cb: Function) => { // 获取买家用户列表
    new BasicsClass().BasicGet('/animal/web/appletUser/buyerList', { ...form, ...page }, false, false, cb, () => { }, false)
}

export const getSellerUserList = (form: any, page: any, cb: Function) => { // 获取卖家用户列表
    new BasicsClass().BasicGet('/animal/web/appletUser/sellerList', { ...form, ...page }, false, false, cb, () => { }, false)
}

export const getMarketUserList = (form: any, page: any, cb: Function) => { // 获取市场用户列表
    new BasicsClass().BasicGet('/animal/web/appletUser/marketerList', { ...form, ...page }, false, false, cb, () => { }, false)
}

export const getMarketListAll = (form: any, cb: Function) => { // 获取所有交易市场列表
    new BasicsClass().BasicGet('/animal/web/appletMarket/listAll', { ...form }, false, false, cb, () => { }, false)
}

export const saveUser = (form: any, cb: Function) => {  //新增用户（市场人员和市场安全员）
    new BasicsClass().BasicPost("/animal/web/appletUser/addSeller", { ...form }, true, true, true, cb)
}

export const checkSeller = (form: any, cb: Function) => { // 审核卖家
    new BasicsClass().BasicGet('/animal/web/appletUser/checkSeller', { ...form }, false, false, cb, () => { }, false)
}

export const getSellerDetail = (form: any, cb: Function) => { // 获取卖家详情
    new BasicsClass().BasicGet('/animal/web/appletUser/getSellerDetail', { ...form }, false, false, cb, () => { }, false)
}
export const getBuyerDetail = (form: any, cb: Function) => { // 获取买家详情
    new BasicsClass().BasicGet('/animal/web/appletUser/getBuyerDetail', { ...form }, false, false, cb, () => { }, false)
}

export const getMarketDetail = (form: any, cb: Function) => { // 获取市场详情
    new BasicsClass().BasicGet('/animal/web/appletUser/getMarketerDetail', { ...form }, false, false, cb, () => { }, false)
}


export const getTurnUrl = (cb: Function) => {
    new BasicsClass().BasicGet('/animal/web/productionAuth/getTurnUrl', {}, false, false, cb, () => { }, false)
}


// 视频置顶
/**
 * 
 * @param deviceCode 设备编号
 * @param topFlag 是否置顶 
 * @param cb 
 */
export const firmTopVideo = (deviceCode: string, topFlag: boolean, cb: Function) => {//企业
    new BasicsClass().BasicPost("/animal/web/newInsuranceLargeScreen/turn2Top", { deviceCode, topFlag }, true, true, true, cb, () => { }, true)
}
export const bankTopVideo = (deviceCode: string, topFlag: boolean, cb: Function) => {//银行
    new BasicsClass().BasicPost("/animal/web/bigScreenForBankNew/turn2Top", { deviceCode, topFlag }, true, true, true, cb, () => { }, true)
}
export const insuranceTopVideo = (deviceCode: string, topFlag: boolean, cb: Function) => {//保险
    new BasicsClass().BasicPost("/animal/web/newEnterpriseLargeScreen/turn2Top", { deviceCode, topFlag }, true, true, true, cb, () => { }, true)
}


// 判断大屏是否具有闪烁灯权限
export const judgeExistWarninLightAuth = (screenName: string, cb: Function) => {
    new BasicsClass().BasicGet('/animal/web/warningSwitch/judgeExistWarninLightAuth', { screenName }, false, false, cb)
}

//萤石云视频
export const getBigScreenGovernmentysVideoGroupTree = (animalTypeId:any,cb: Function) => { //萤石云组织机构
    new BasicsClass().BasicGet('/animal/web/newInsuranceLargeScreen/ysGroupTree', { animalTypeId }, false, false, cb)
}
export const getBigScreenGovernmentysVideoList = (form: any, page: any, cb: Function) => {//萤石云视频
    new BasicsClass().BasicGet('/animal/web/newInsuranceLargeScreen/ysVideoList', { ...form, ...page, animalTypeId: 2, streamType: 1 }, false, false, cb, () => { }, false)
}

